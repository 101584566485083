<template>
    <div>
      <default-app-bar > 
         <template v-slot:title>
          <div class="text-h3 mr-2"> Cartera por Edades </div>
         </template>
    
         
         <!--template v-slot:actions>
              <router-link  to="/admin/solicitudes/list-desembolsado"  class="mr-2 white-text" >
                Volver
              </router-link>
          
         </template-->
       </default-app-bar>
       <v-card-text v-if="form">
          <v-container>
           <v-row no-gutters> 
            <v-col cols="12" sm="12" class="form-col"  > 
                <ng-integer  v-bind:form="form" :size="12"  v-bind:options="config.id"></ng-integer>
            </v-col>
            
            <v-col cols="12" sm="12" class="form-col"  > 
                <ng-chips  v-bind:form="form" :size="12"  v-bind:options="config.bus_person_cliente_id"></ng-chips>
            </v-col>
            <!--v-col cols="12" sm="6" class="form-col"  > 
                <ng-date-range  v-bind:form="form" :size="12"  v-bind:options="config.fecha_desembolso"></ng-date-range>
            </v-col->
            <!--v-col cols="12" sm="6" class="form-col"  > 
                <ng-select-multi  v-bind:form="form" size="12"  v-bind:options="config.bus_producto_id"></ng-select-multi>
            </v-col-->
       
            <v-col cols="12" sm="12" class="text-right"> 
            <v-btn v-on:click="search()" block color="primary">Buscar</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <div v-if="tmpl" :key="renderKey" class="pa-4">
            <!--div class="text-right">
              <v-btn size="small" icon v-on:click="excel()"><v-icon>mdi-file-excel-box</v-icon></v-btn>
            </div-->
            <component :is="getComponent()" :info="info"  ></component>
      </div>
  </div>
</template>

<script>
  import { NgForm, handler } from '@/components/gui/NgForm';
  import { VCard, VCardText, VCardTitle, VIcon, VContainer, VRow, VCol, VBtn, VDialog, VDataTable, VSimpleTable, VCombobox, VSelect, VPagination, VDivider, VList, VListItem, 
    VListItemTitle, VListItemContent, VListItemSubtitle, VListItemAction, VMenu} from 'vuetify/lib';

  export default {
    name: 'NgFormReportDetalleCarteraEdades',
    data(){
        return {
          form: null, //{ bus_person_cliente_id: null, fecha_desembolso: { from: null, to: null } },
          config: {
            id: { label: 'Crédito', name: 'id'  },
            bus_person_cliente_id: { label: 'Cliente', dense: false, name: 'bus_person_cliente_id', url: 'api/v1/admin/person/search' },
            //fecha_desembolso: { label: 'Fecha', name: 'fecha_desembolso' },
            //bus_producto_id: { label: 'Producto', name: 'bus_producto_id', url: 'api/default/bus_producto/search' },
            /*bus_medio_pago_id: { label: 'Medio de desembolso', name: 'bus_medio_pago_id', url: 'api/default/bus_medio_pago/search' },
            bus_convenio_admin_cartera_id: { label: 'Origen de Fondos', name: 'bus_convenio_admin_cartera_id', url: 'api/admin/person/search-inver' },*/
          },
          renderKey: 1,
          info: null,
          tmpl: `
          `
        }
    },
    mounted () {
         //console.log(this.pageConfg)
         const form = new NgForm(this.config, this);
         this.form = new Proxy(form, handler);
         //this.loadItem();
    },
    methods: {
      getFormValue(){
        const value = this.form.value;
        //value['_conv_per_id'] = this.user.personId;
        return value;
      },
 
      toogleDrawer(){
           this.$store.commit('toggle')
      },
      search(){
        this.$http.queryReport( 'report/html/detalle-cartera-edades', { data: this.getFormValue() }  )
        .then(res => {
          console.log(res)
            this.info = res.data.info;
            this.tmpl = res.data.html;
            this.renderKey++;
        }).catch(err => {
          console.log(err);
        });
      },
      excel(){
        const q = serialize_query( this.getFormValue() );
        window.open('https://reportes.serprogreso.com/' + 'report/excel/detalle-cartera-edades?' + q, '_blank')
      },
      getComponent(){
        const tmpl = this.tmpl;
          return {
              data(){
                return {
                  data: null
                } 
              },
              props: ['info'],
              components: { VCard, VCardText, VCardTitle, VIcon, VContainer, VRow, VCol, VBtn, VDialog, 
          VDataTable, VSimpleTable, VCombobox, VSelect, VPagination, VDivider, VList, VListItem, VListItemTitle, 
          VListItemContent, VListItemSubtitle, VListItemAction, VMenu },
              template: tmpl,
              mounted(){
                this.data = this.info
              }
          }
      }
    }, 
  }
</script>
<style>
  .detalle-cartera th{
    background-color: #ccc;
    padding: 0 2px 0px 4px;
  }
</style>
